
/**
 * @desc api接口的统一出口
 */

// 模块接口
import ApplyService from '@/services/Apply.js' // 作品管理接口

// 导出接口
export default {
  ApplyService
}
