// axios-init.ts
import axios from 'axios'
import handleErrcode from '@/utils/response-code.js' // 错误码
// import API from '@/services/index.js'
const { VUE_APP_BASIC_API: baseUrl } = process.env
axios.defaults.baseURL = baseUrl // 配置axios请求的地址
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.post['Content-Type'] = 'application/json charset=utf-8'
// axios.defaults.crossDomain = true
// axios.defaults.withCredentials = true // 设置cross跨域 并设置访问权限 允许跨域携带cookie信息
// axios.defaults.headers.common.Authorization = '' // 设置请求头为 Authorization

const instance = axios.create({
  headers: {
    'content-type': 'application/json',
  }
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // let Authorization = API.LocalStorage.Get('accessUser') // Authorization进行token过期验证
    // Authorization = Authorization ? Authorization = 'Bearer ' + Authorization : Authorization
    // if (Authorization) {
      config.headers.Authorization = 'Basic S2FuRGFvVlI6a2FuZGFvdnIhQCN0ZWNo'
    // }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200 || response.status === 204) {
      const { code, result } = response.data
      if (code === 200 || code === 0) {
        return Promise.resolve(result)
      } else {
        handleErrcode(code)
        return Promise.reject(response.data)
      }
    } else {
      return Promise.reject(response.data)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
