<template>
  <div>
    <div class="home">
      <div class="home_binner">
        <div ref="viewer" id="viewer" @mouseup="viewerMouseup" @touchend="viewerMouseup"></div>
        <div class="title binner_title z_index">沉浸式VR交互平台</div>
        <div class="sub_title binner_sub_title z_index">让每个人都成为元宇宙的构建师</div>
        <div class="z_index">
          <a-button class="apply_button home_button" @click="showModal = true">免费试用</a-button>
          <br v-if="!isPC">
          <a :href="appDownloadUrl">
            <a-button class="download_button home_button">下载软件</a-button>
          </a>
          <br>
          <div class="content_sub_title no_code_sub_title" style="color: #fff; font-weight: normal;">立即注册免费获取一个月试用权限。</div>
        </div>
      </div>
      <!-- 无代码编程，0门槛上手 -->
      <div class="no_code">
        <div class="title no_code_title">无代码编程，0门槛上手</div>
        <div class="content_sub_title no_code_sub_title">简单易用的VR内容创作软件，提供畅爽的人机交互操作体验，让创作VR内容更简单。</div>
        <a-button class="apply_button home_button kandao-apply-button" @click="showModal = true">免费试用</a-button>
        <br>
        <video ref="video" autoplay x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true"
          playsinline="true" crossorigin="anonymous" preload="auto" webkit-crossorigin="anonymous" 
          :controls='false' muted loop @canplay="videoCanPlay" poster="@/assets/img/video_poster.png">
          <source src="https://resource.kandao360.com/web/creator_video_1.mp4">
        </video>
      </div>
      <!-- 各种素材都能用，讲更精彩的故事 -->
      <div class="more_choose">
        <div class="title more_choose_title">各种素材都能用,<br v-if="!isPC">讲更精彩的故事</div>
        <div class="content_sub_title more_choose_sub_title">支持各类素材格式，满足各类创作需求, 让作品更精彩。</div>
        <a-button class="apply_button home_button kandao-apply-button" @click="showModal = true">免费试用</a-button>
        <div class="more_choose_icon">
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_1@2x.png" alt="">
            <p>全景视频</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_2@2x.png" alt="">
            <p>全景照片</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_3@2x.png" alt="">
            <p>直播流</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_4@2x.png" alt="">
            <p>传统视频</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_5@2x.png" alt="">
            <p>高清大图</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_6@2x.png" alt="">
            <p>语音</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_7@2x.png" alt="">
            <p>文本</p>
          </div>
          <div class="more_choose_icon_box">
            <img src="@/assets/img/03_icon_8@2x.png" alt="">
            <p>其他</p>
          </div>
        </div>
      </div>
      <!-- 一键分享，让所有人体验你的元宇宙 -->
      <div class="share_simple">
        <div class="title share_simple_title">一键分享，<br v-if="!isPC">让所有人体验你的元宇宙</div>
        <div class="content_sub_title share_simple_sub_title">直接上传到平台分享，可在电脑、手机、平板等各种设备上实现高质量的VR内容浏览。</div>
        <a-button class="apply_button home_button kandao-apply-button" @click="showModal = true">免费试用</a-button>
        <br>
        <img src="@/assets/img/05_img@2x.png" alt="">
      </div>
      <!-- 什么场景都好用，点击案例感受一下 -->
      <div class="more_usage_scenarios">
        <div class="title more_usage_scenarios_title">什么场景都好用，<br v-if="!isPC">点击案例感受一下</div>
        <div class="content_sub_title more_usage_scenarios_sub_title">全景VR案例，感受更直观、更真实，更生动。</div>
        <a-button class="apply_button home_button" @click="showModal = true">免费试用</a-button>
        <!-- 素材 -->
        <div class="material_box">
          <div class="material">
            <a target="_blank" href="https://play.kandao360.com/?projectId=797222fee8634b53ab65b2f00c1ab0ed">
              <img src="@/assets/img/06_2_img@2x.png" alt="">
              <p>洛克线徒步</p>
            </a>
          </div>
          <div class="material">
            <a target="_blank" href="https://play.kandao360.com/?projectId=59285c6ac0544eb3989c53e46742b36d">
              <img src="@/assets/img/06_3_img@2x.png" alt="">
              <p>双清别墅</p>
            </a>
          </div>
          <div class="material">
            <a target="_blank" href="https://play.kandao360.com/?projectId=8fd663e916c34c458cf46a8d5612507a">
              <img src="@/assets/img/06_4_img@2x.png" alt="">
              <p>虚拟展厅</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 30天试用申请 -->
    <a-modal v-model="visible" title="30天试用申请" @ok="handleSubmit" cancelText="取消" 
      okText="提交申请" :centered="true" :destroyOnClose="true" @cancel="cancelModal"
      :maskClosable="false" >
      <a-form-model ref="ruleForm" :label-col="{ span: 24 }" :wrapper-col="{ span: 24}" :rules="rules" :model="form">
        <!-- 用户名称 -->
        <a-form-model-item :colon="false" required label="姓名" prop="username">
          <a-input placeholder="请输入用户名称" :maxLength="10" v-model="form.username" />
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item :colon="false" required label="邮箱" prop="email" class="set-text">
          <a-input placeholder="请输入邮箱地址" v-model="form.email" />
        </a-form-model-item>
        <!-- 手机号 -->
        <a-form-model-item :colon="false" label="手机号" required prop="cellphone">
          <a-input placeholder="请输入手机号" v-model="form.cellphone" />
        </a-form-model-item>
        <!-- 行业 -->
        <a-form-model-item :colon="false" label="行业" prop="industry">
          <a-select defaultValue="影像服务" style="width: 100%" v-model="form.industry">
            <a-select-option value="影像服务">影像服务</a-select-option>
            <a-select-option value="广告传媒">广告传媒</a-select-option>
            <a-select-option value="室内设计">室内设计</a-select-option>
            <a-select-option value="家居装修">家居装修</a-select-option>
            <a-select-option value="房地产">房地产</a-select-option>
            <a-select-option value="建筑工地">建筑工地</a-select-option>
            <a-select-option value="新闻媒体">新闻媒体</a-select-option>
            <a-select-option value="汽车">汽车</a-select-option>
            <a-select-option value="政府公益">政府公益</a-select-option>
            <a-select-option value="生活服务">生活服务</a-select-option>
            <a-select-option value="教育培训">教育培训</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 公司 -->
        <a-form-model-item :colon="false" label="公司" prop="enterprise">
          <a-input placeholder="请输入公司名称" :maxLength="20" v-model="form.enterprise" />
        </a-form-model-item>
        <!-- 所在地 -->
        <a-form-model-item :colon="false" label="所在地" prop="address">
          <a-input placeholder="请输入所在地" v-model="form.address" />
        </a-form-model-item>
        <!-- 和种渠道得知 -->
        <a-form-model-item :colon="false" required label="何种渠道得知" prop="way">
          <a-select default-value="销售推荐" style="width: 100%" v-model="form.way">
            <a-select-option value="销售推荐">销售推荐</a-select-option>
            <a-select-option value="朋友推荐">朋友推荐</a-select-option>
            <a-select-option value="网络搜索">网络搜索</a-select-option>
            <a-select-option value="官网">官网</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 注册弹窗 -->
    <RegisterModal :show-modal="showModal" @cancelModal="showModal = false"></RegisterModal>
  </div>
</template>

<script>
import API from '@/services'
import Modal from 'ant-design-vue/lib/modal';
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
// import { CubemapAdapter } from 'photo-sphere-viewer/dist/adapters/cubemap'; // 六方体贴图工具
import RegisterModal from '@/components/RegisterModal'
export default {
  name: 'ApplyHome',
  components: {
    RegisterModal
  },
  metaInfo: {
    title: 'Kandao Creator | 无门槛交互媒体创作工具 | VR漫游创作工具',
    meta: [
      {
        name: 'keywords',
        content: 'vr,看到科技,vr全景,vr全景制作,vr漫游,全景漫游,交互视频, 交互制作,H5,creator'
      },
      {
        name: 'description',
        content: 'Kandao Creator，低门槛交互媒体创作工具，打破线性叙事的局限，以连接、融合、传播的能力，为各行业提供全新的内容呈现形式。'
      }
    ]
  },
  data () {
      return {
        visible: false,
        PSV: null,
        panoramaImgIndex: 0,
        changeImgTime: null,
        lastTime: new Date().getTime(),
        psvContainer: null,
        psvCanvasContainer: null,
        psvLoaderContainer: null,
        limitTime: 1,
        isIOS: /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent),
        showModal: false,
        appDownloadUrl: process.env.VUE_APP_DOWNLOAD_SOFTWARE_URL || '',
        panoramaImgArr: [
          'https://resource.kandao360.com/web/panoramic_1.jpg',
          'https://resource.kandao360.com/web/panoramic_2.jpg',
          'https://resource.kandao360.com/web/panoramic_3.jpg',
          'https://resource.kandao360.com/web/panoramic_4.jpg'
        ],
        form: {
          username: '', // 用户姓名
          email: '', // 邮箱
          cellphone: '', // 手机
          industry: '影像服务', // 行业
          enterprise: '', // 企业单位
          address: '', // 地址
          way: '销售推荐', // 了解渠道
        },
        rules: {
          username: [
            { required: true, message: '请输入用户姓名' },
            { required: true, 
              validator: (rule, value, callback) => {
                const reg  = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g
                if (reg.test(value)) {
                  return callback('不能输入表情！')
                } else {
                  return callback()
                }
              }, 
            }
          ],
          email: [ 
            { type: 'email', message: '请输入正确的邮箱地址!', },
            { required: true, message: '请输入你的邮箱地址!', }
          ],
          cellphone: [
            { required: true, message: '请输入手机号' },
            { required: true, message: '最多可输入11位', max: 11 },
            { required: true, message: '请输入正确的手机号', 
              validator: (rule, value, callback) => {
                if (value && !/^1[3456789]\d{9}$/.test(value)) {
                  return callback('请输入正确的手机号')
                } else {
                  return callback()
                }
              }, 
            }
          ],
          way: [{ required: true, message: '请选择渠道' }],
          enterprise: [
            { required: false, 
              validator: (rule, value, callback) => {
                const reg  = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g
                if (reg.test(value)) {
                  return callback('不能输入表情！')
                } else {
                  return callback()
                }
              }, 
            }
          ],
          address: [
            { required: false, 
              validator: (rule, value, callback) => {
                const reg  = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g
                if (reg.test(value)) {
                  return callback('不能输入表情！')
                } else {
                  return callback()
                }
              }, 
            }
          ]
        },
      }
  },

  computed: {
    isPC: function () {
      return this.$store.state.isPC
    }
  },

  methods: {
    applyForm() {
      this.visible = true
    },

    videoCanPlay () {
      this.$refs.video.play()
      if (this.isIOS) {
        this.$refs.video.controls = true
        this.$refs.video.autoplay = false
      }
    },

    initPhotoSphere() {
      this.PSV = new Viewer({
        container: this.$refs.viewer,
        panorama: this.panoramaImgArr[this.panoramaImgIndex],
        // adapter: CubemapAdapter, // 六方体贴图
        // panorama: {
        //   left:   that.panoramaImgArr[0],
        //   front:  that.panoramaImgArr[1],
        //   right:  that.panoramaImgArr[2],
        //   back:   that.panoramaImgArr[3],
        //   top:    that.panoramaImgArr[4],
        //   bottom: that.panoramaImgArr[5],
        // },
        mousewheel: false,
        navbar:false,
        // autorotateDelay: 10, // 开启自动旋转延时
        autorotateSpeed: '0.8rpm' // 旋转速度，默认2rpm 
      })
    },

    // 鼠标弹起
    viewerMouseup() {
      var that = this,
      time;
      if(time) {
        clearTimeout(time)
        return
      }
      time = setTimeout(function () {
        that.PSV.startAutorotate()
      }, 2000)
      // 将切换全景图的时间重置，防止用户看图时切换
      this.lastTime = new Date().getTime()
    },

    // 提交表单
    handleSubmit() {
      const that = this;
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await API.ApplyService.UserApply(this.form)
          Modal.success({
            title: (
              <div style="font-weight: 600">
                <p>提交申请成功，请耐心等待。</p>
                <p>申请结果请邮件查收</p>
              </div>
            ),
            centered: true,
            okText: '确认',
            onOk: function () {
              that.visible = false
              that.form = {
                username: '', // 用户姓名
                email: '', // 邮箱
                cellphone: '', // 手机
                industry: '影像服务', // 行业
                enterprise: '', // 企业单位
                address: '', // 地址
                way: '销售推荐', // 了解渠道
              }
            }
          })
        }
      });
    },

    // 关闭弹窗
    cancelModal() {
      this.form = {
        username: '', // 用户姓名
        email: '', // 邮箱
        cellphone: '', // 手机
        industry: '影像服务', // 行业
        enterprise: '', // 企业单位
        address: '', // 地址
        way: '销售推荐', // 了解渠道
      }
    },

    setPanorama () {
      var that = this;
      if (new Date().getTime() - that.lastTime > 10000) {
        that.lastTime = new Date().getTime()
        that.panoramaImgIndex = that.panoramaImgIndex >= that.panoramaImgArr.length - 1 ? 0 : ++that.panoramaImgIndex;
        that.PSV.setPanorama(that.panoramaImgArr[that.panoramaImgIndex]).then(() => {
          that.psvCanvasContainer.style = 'background: rgb(0, 0, 0); cursor: move; opacity: 0.7;'
        })
      }
      if (this.limitTime == 1 && new Date().getTime() - that.lastTime > 1500) {
        this.limitTime = 2
        this.PSV.startAutorotate()
      }

      requestAnimationFrame(this.setPanorama)
    },
  },
  mounted() {
    var that = this;
    this.$nextTick(() => {
      that.initPhotoSphere()
      // 去除loading效果
      that.psvContainer = document.getElementsByClassName("psv-container")[0];
      that.psvCanvasContainer = document.getElementsByClassName("psv-canvas-container")[0];
      that.psvLoaderContainer = document.getElementsByClassName("psv-loader-container")[0];
      that.psvLoaderContainer.remove();
      that.psvContainer.style = 'background: #000;'

      that.PSV.on('ready', function () {
        that.setPanorama()
        that.psvContainer.style = 'background: #000;'
        that.psvCanvasContainer.style = 'background: rgb(0, 0, 0); cursor: move; opacity: 0.7;'
      })
    })
  }
}
</script>

<style scoped lang="scss">
.home {
  .ant-btn {
    height: auto;
  }

  .content_sub_title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

  .apply_button {
    font-weight: 600;
    margin: 25px 0;
  }

  .home_binner {
    position: relative;
    height: 650px;
    padding: 140px 180px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-size: 100% 100%;
    user-select: none;

    #viewer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .title, .sub_title {
      font-size: 32px;
    }
  }

  .title, .sub_title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .binner_title {
    background-image: -webkit-linear-gradient(left, #26DEDE, #3197D6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .binner_sub_title {
    color: #fff;
  }

  .home_button {
    padding: 8px 33px;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    background: transparent;
    font-size: 8px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F2F2F2;
  }

  .apply_button {
    background: linear-gradient(113deg, #26DEDE 0%, #3197D6 100%);
    border: 0;
    color: #fff;
    margin-right: 16px;
  }

  // 无代码编程 各种素材都能用，讲更精彩的故事
  .no_code, .more_choose {
    text-align: center;
    padding: 48px 0;

    .no_code_title {
      color: #333333;
    }

    .more_choose_title {
      background-image: -webkit-linear-gradient(left, #26DEDE, #3197D6);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    video {
      width: 800px;
      margin-top: 38px;
    }

    .more_choose_icon {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .more_choose_icon_box {
      width: 25%;
      margin-top: 40px;

      img {
        width: 70px;
      }

      p {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  // 试用申请按钮
  .no_code, .more_choose, .share_simple {
    .apply_button {
      color: #23b7cb;
      background: #fff;
      border-radius: 20px;
      position: relative;
      margin-right: 0;
    }

    .kandao-apply-button {
      border: 1px transparent solid;
    }

    .apply_button:after {
      content:'';
      position: absolute;
      top: -3px; bottom: -3px;
      left: -3px; right: -3px;
      background: linear-gradient(135deg,#26DEDE, #3197D6);
      border-radius: 20px;
      content: '';
      z-index: -1;
    }
  }

  .more_choose .content_sub_title {
    background-image: -webkit-linear-gradient(left, #26DEDE, #3197D6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .more_choose, .share_simple {
    padding: 78px 180px;
  }

  .more_usage_scenarios {
    padding: 48px 180px;
  }
  // 一键分享，让所有人体验你的元宇宙 
  .share_simple {
    text-align: center;

    .share_simple_title {
      color: #333333;
    }

    img {
      width: 444px;
      margin-top: 33px;
    }
  }

  // 什么场景都好用，点击案例感受一下
  .more_usage_scenarios {
    text-align: center;
    background: #242529;

    .more_usage_scenarios_title, .content_sub_title {
      background-image: -webkit-linear-gradient(left, #26DEDE, #3197D6);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .apply_button {
      color: #242529;
      margin-right: 0;
    }

    .material_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 36px;

      .material {
        width: 49%;
        height: 100%;
        border-radius: 8px;
        position: relative;
        margin-bottom: 24px;
      }

      img {
        width: 100%;
        height: 340px;
        border-radius: 8px;
      }

      p {
        padding: 6px 8px;
        text-align: left;
        font-weight: bold;
        color: #FFFFFF;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.4);
        margin-bottom: 0;
        border-radius: 0 0 8px 8px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  .z_index {
    z-index: 2;
  }
}

@media screen and (max-width: 980px) { // 移动端样式
  .home {
    .ant-btn {
      font-size: 16px;
    }

    .title, .sub_title {
      font-size: 26px;
      margin-bottom: 18px;
    }

    .home_binner {
      padding: 0 24px;
      align-items: center;
      height: 450px;

      .apply_button {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .sub_title {
        font-size: 18px;
      }
    }

    .no_code {
      padding: 66px 24px;

      video {
        width: 100%;
        margin-bottom: 26px;
      }
    }

    .more_choose {
      padding: 24px;

      .more_choose_icon_box {
        width: 50%;

        p {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      img {
        margin-top: 26px;
      }
    }

    .share_simple {
      padding: 66px 24px;

      img {
        width: 100%;
      }
    }

    .more_usage_scenarios {
      padding: 66px 24px;

      .material_box {
        margin-top: 24px;

        .material {
          width: 100%;
        }

        img {
          height: 192px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }
}

</style>