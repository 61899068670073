import { message } from 'ant-design-vue'
import API from '@/services/index'

function handleErrcode(code) {
  switch (code) {
    case 500:
      message.error('内部服务错误!');
      break;
    // # common code
    case 10000:
      message.error("服务器错误!");
      break;
    case 10001:
      message.error("请求参数错误!");
      break;
    case 10002:
      message.error("验证码过期!");
      break;
    case 10003:
      message.error("验证码错误!");
      break;
    case 10004:
      message.error("输入的账号或密码错误!");
      break;
    case 10005:
      message.error("token错误!");
      setTimeout(function () {
        API.LocalStorage.Clear()
        window.location.href = '#/login';
      }, 1000);
      break;
    case 10006:
      message.error("验证已过期!");
      break;
    case 10007:
      message.error("用户不存在!");
      break;
    case 10008:
      message.error("请登录!");
      setTimeout(function () {
        API.LocalStorage.Clear()
        window.location.href = '#/login';
      }, 1000);
      break;
    case 10009:
      message.error("对象不存在!");
      break;
    case 10010:
      message.error("网络错误!");
      break;
    case 10011:
      message.error("权限禁止!");
      break;
    case 10012:
      message.error("http请求方法错误!");
      break;
    case 10013:
      message.error("未知错误!");
      break;
    case 10014:
      // message.error("登录token已过期!");
      setTimeout(async function () {
        const refresh = API.LocalStorage.Get('refreshUser')
        try {
          const res = await API.LoginService.RefreshToken({ refresh, is_staff: 0 })
          if (res.access) {
            API.LocalStorage.Update('accessUser', res.access)
            API.LocalStorage.Update('refreshUser', res.refresh)
            window.location.reload()
          }
        } catch (error) {
          // API.LocalStorage.Clear()
          // window.location.href = '#/login';
        }
      }, 0);
      break;
    case 10015:
      message.error("新密码和旧密码不能一样!");
      break;
    case 10016:
      message.error("密码错误!");
      break;
    case 10017:
      message.error("账号已被删除!");
      break;
    case 10018:
      message.error("登录已过期，请重新登录!");
      setTimeout(function () {
        API.LocalStorage.Clear()
        window.location.href = '#/login';
      }, 1000);
      break;
    case 10020:
      message.error("无权做此操作!");
      break;

    // 用户相关
    case 10100:
      message.error("手机号已存在!");
      break;
    case 10102:
      message.error("一分钟内只能请求一次!");
      break;

    // 项目相关
    case 10201:
      message.error("没有该对象!");
      break;
    case 20001:
      message.error("参数错误!");
      break;
    case 20002:
      message.error("无效用户!");
      break;
    case 20003:
      message.error("时间格式不正确!");
      break;
    case 20010:
      message.error("邮件发送失败!");
      break;
    case 20014:
      message.error("账户无权限!");
      break;
    case 20101:
      message.error("用户已经存在!");
      break;
    case 20102:
      message.error("用户不存在!");
      break;
    case 20103:
      message.error("超级用户帐号不能被冻结或删除!");
      break;
    case 20104:
      message.error("无法修改自己账户状态!");
      break;
    case 20105:
      message.error("无法删除自己账户!");
      break;
    case 20106:
      message.error("超级用户帐号不能被重置密码!");
      break;
    case 20107:
      message.error("角色名称已存在!");
      break;
    case 20108:
      message.error("该角色不存在!");
      break;
    case 20109:
      message.error("系统用户无法被删除!");
      break;
    case 20110:
      message.error("系统用户密码不能重置!");
      break;
    case 20201:
      message.error("用户已提交试用申请!");
      break
  }
}
export default handleErrcode;
